import React from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Userlist = (props) => {
    const navigate = useNavigate();

    const {id, username, voteridno, gender, dob, fathername, imgurl} = props
     


    const handleDelete = () => {
        const id= props.id;
        try {
            axios.post("http://localhost:3001/deleteu", {id})
            .then(response => {console.log(response) })
                .catch((err) => {console.log(err)})
            
        } catch (error) {
          console.log(error)  
        }
    }
  return (
//card form//
    <div className='employeeList'>
        <div className="card">
        <div className="top">
        <img className="circle-img" src= {`../images/${props.imgurl}`} alt="avatar_img"/> 
        {/* <p className="info">Id: {props.cno}</p>  */}
        </div>
        <div className="bottom">
        <p className="info">Name: {props.username}</p> 
        <p className="info">Voter Id no.: {props.voteridno}</p> 
        <p className="info">Gender.: {props.gender}</p> 
        <p className="info">DOB: {props.dob}</p> 
        <p className="info">Father Name: {props.fathername}</p> 
       
           
          <button onClick={handleDelete}>Delete</button>
          <button onClick={() => {navigate(`/updateu/${props.id}`)}}>Update</button>
        </div>
      </div>
      </div>

      //tabular form//
    // <div className='employeeData'>
    // <div>{id}</div>
    // <div>{username}</div>
    // <div>{voteridno}</div>
    // <div>{gender}</div>
    // <div>{dob}</div>
    // <div>{fathername}</div>
    // <div>{imgurl}</div>
     

    // <button onClick={handleDelete}>Delete</button>
    // <button onClick={() => {navigate(`/updateu/${id}`)}}>Update</button>
    // </div>
  )
}

export default Userlist;