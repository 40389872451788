import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Candidatelist from './Candidatelist'

const Candidate = () => {
    const navigate = useNavigate();
    const [candidate, setCandidate] = useState([]);

    

    useEffect(() => {  
        axios
        .get("http://localhost:3001/candidate")
        .then(
            (response) => {
                // console.log(response.data);
                setCandidate(response.data);
            }
            )
        .catch(
            (error) => {
                console.log(error)
            }
        )

    }, [candidate]);


  return (
    <>

    <center><h1>Candidate</h1>
    
    </center>
    <div className='header'>
        <div>id</div>
        <div>cno</div>
        <div>cname</div>
        <div>age</div>
        <div>party</div>
        <div>symble</div>
        <div>totalvote</div>
          
    </div>
    
    
    {candidate.map(
    (candidate) => {
        

    return(
        <Candidatelist
    key={candidate.id}
    id={candidate.id}
    cno={candidate.cno}
    cname={candidate.cname}
    age={candidate.age}
    party={candidate.party}
    symble={candidate.symble}
    totalvote={candidate.totalvote}
    />
    )
    }

    )
    }
     

    <button onClick={()=>{navigate("/AddDatac")}}>Add Data</button>
    <button onClick={()=>{navigate("/Report")}}>Go Back</button>
    </>
  )
}

export default Candidate