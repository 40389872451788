import Dropdown from 'react-bootstrap/Dropdown';
function Navdrop() {
  return (
    <>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        Dropdown Button
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">sudheeksha</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Vathsala</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Sameeksha</Dropdown.Item>
      </Dropdown.Menu>
    </>
  );
}

export default Navdrop;
