import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Form from 'react-bootstrap/Form';


const Vote = () => {
    const [candidate, setCandidate] = useState([]); 
    const [vote, setVote] = useState([]);   
    
    const [votedFor, setVotedFor] = useState("");
    useEffect(() => {  
        axios
        .get("http://localhost:3001/candidate")
        .then(
            (response) => {
                // console.log(response.data);
                setCandidate(response.data);
            }
            )
        .catch(
            (error) => {
                console.log(error)
            }
        )
   
    }, [candidate]);


    useEffect(() => {  
        axios
        .get("http://localhost:3001/Users")
        .then(
            (response) => {
                // console.log(response.data);
                setVote(response.data);
            }
            )
        .catch(
            (error) => {
                console.log(error)
            }
        )
   
    }, [vote]);



  const navigate=useNavigate();

  function updateAllData(){
    axios
    .get("http://localhost:3001/updateAllData")
    .then((response) => {
      console.log(response);
      alert(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
    }
 
function handleChange(event){
    console.log(event.target.selectedIndex);
    console.log(candidate[event.target.selectedIndex]);
    axios
    .post("http://localhost:3001/vote", candidate[event.target.selectedIndex])
    .then((response) => {
      console.log(response);
      alert(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}


function handleChange1(event){
    console.log(event.target.selectedIndex);
    console.log(vote[event.target.selectedIndex]);
    axios
    .post("http://localhost:3001/vote1", vote[event.target.selectedIndex])
    .then((response) => {
      console.log(response);
      alert(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}




  return (
    <>

    <center>
   
    <h1>Please Vote here...</h1> 
    <div>Select Candidate name below </div>

    <div className='abcd'>
    <div className='displayflex'>
    <Form.Select className='drop' aria-label="Default select example" onChange={handleChange}>

         
        {candidate.map( (candidate) => {
        return(
            <option   value={candidate.cname}>{candidate.cname}</option>
             
        )})}

        </Form.Select>
        <div>Select voter name below</div>
        </div> 


        <Form.Select className='drop' aria-label="Default select example" onChange={handleChange1}>

         
{vote.map( (vote) => {
return(
    <option value={vote.username}>{vote.username}</option>
)})}
</Form.Select>
</div>
      <button className='button-62' onClick={()=>{navigate("/")}}>Go Back</button>
  {/* <img className='imgE' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdnkfmur_HTrqI9ObPNVGeYdZAD2lUVdNreg&usqp=CAU" width="1200" height="500" alt="" /> */}
    </center>
   
    </>
  )
}

export default Vote;
