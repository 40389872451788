import React from 'react'
import { useNavigate } from 'react-router-dom'

const Report = () => {
    const navigate = useNavigate();


  return (
    <>
    <button onClick={()=>{navigate("/User")}}>Voters List</button>
    <button onClick={()=>{navigate("/Candidate")}}>Candidate List</button>
    <button onClick={()=>{navigate("/")}}>Go Back</button>
    </>
  )
}

export default Report;