import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AddDatau = () => {

    const navigate = useNavigate();
    const [username, setUsername] = useState("")
    const [voteridno, setVoteridno] = useState("")
    const [gender, setGender] = useState("")
    const [dob, setDob] = useState("")
    const [fathername, setFathername] = useState("")
    const [imgurl, setImgurl] = useState("")
     
    

    const handleSubmit = (e) => {
        e.preventDefault()

        try {
            axios
            .post("http://localhost:3001/insertu", {username, voteridno, gender, dob, fathername, imgurl})
            .then(
                (response) =>{
                    console.log(response)
                navigate('/User')
                })
            .catch(
                err =>console.log(err)
            )
        } catch (error) {
            
        }

    }

  return (
    <>
    <div>
    <form onSubmit={handleSubmit}>
        <input placeholder='Enter User name' type = 'text' name = 'username' value={username} onChange = {(e) =>setUsername(e.target.value)}  required />
        <input placeholder='Voter id no' type = 'number' name = 'voteridno' value={voteridno} onChange = {(e) =>setVoteridno(e.target.value)} required />
        <input placeholder='gender' type = 'text' name = 'gender' value={gender} onChange = {(e) =>setGender(e.target.value)} required />
        <input placeholder='DOB' type = 'number' name = 'dob' value={dob} onChange = {(e) =>setDob(e.target.value)} required />
        <input placeholder='Father name' type = 'text' name = 'fathername' value={fathername} onChange = {(e) =>setFathername(e.target.value)} required />
        <input placeholder='Imgurl' type = 'text' name = 'imgurl' value={imgurl} onChange = {(e) =>setImgurl(e.target.value)} required />
        <input type = 'submit' value = "submit"/>
    </form>

    </div>
    <button onClick={()=>navigate('/')}>Go Back</button>
    </>
  )
}

export default AddDatau;