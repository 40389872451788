import React from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Candidatelist = (props) => {
    const navigate = useNavigate();

    const {id, cno, cname, age, party, symble, totalvote} = props
     
    const handleDelete = () => {
        const id= props.id;
        try {
            axios.post("http://localhost:3001/deletec", {id})
            .then(response => {console.log(response) })
                .catch((err) => {console.log(err)})
            
        } catch (error) {
          console.log(error)  
        }
    }
  return (
    <div className='employeeData'>
    <div>{id}</div>
    <div>{cno}</div>
    <div>{cname}</div>
    <div>{age}</div>
    <div>{party}</div>
    <div>{symble}</div>
    <div>{totalvote}</div>
     

    <button onClick={handleDelete}>Delete</button>
    <button onClick={() => {navigate(`/updatec/${id}`)}}>Update</button>
    </div>
  )
}

export default Candidatelist;