import React, {useState, useEffect} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import axios from 'axios'

const Updatec = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [cno, setCno] = useState("")
    const [cname, setCname] = useState("")
    const [age, setAge] = useState("")
    const [party, setParty] = useState("")
    const [symble, setSymble] = useState("")
    const [totalvote, setTotalvote] = useState("")
    

    useEffect(() => { 
        axios.post("http://localhost:3001/getUpdateDatac", {id}) 
            .then(
                (response) => {
                    setCno(response.data.cno);
                    setCname(response.data.cname);
                    setAge(response.data.age);
                    setParty(response.data.party);
                    setSymble(response.data.symble);
                    setTotalvote(response.data.totalvote);
                     
                })
            .catch(
                err =>{console.log(err)},
            )
    },[id])
            const handleSubmit = (e) =>{
                e.preventDefault();
                try {
                    axios.post("http://localhost:3001/updatec", {id, cno, cname, age, party, symble, totalvote})
                    .then ((response)=>{
                        console.log(response);
                        navigate('/Candidate');
                    })
                    .catch((err) => {console.log(err) })
                }catch(err) {
                }
            };
                

  return (
    <div>
        <form onSubmit={handleSubmit}>
        <input placeholder='Enter cno' type = 'number' name = 'cno' value={cno} onChange = {(e) =>setCno(e.target.value)}  required />
        <input placeholder='Candidate Name' type = 'text' name = 'cname' value={cname} onChange = {(e) =>setCname(e.target.value)} required />
        <input placeholder='Age' type = 'number' name = 'age' value={age} onChange = {(e) =>setAge(e.target.value)} required />
        <input placeholder='Party' type = 'text' name = 'party' value={party} onChange = {(e) =>setParty(e.target.value)} required />
        <input placeholder='Symble' type = 'text' name = 'symble' value={symble} onChange = {(e) =>setSymble(e.target.value)} required />
        <input placeholder='Totalvote' type = 'number' name = 'totalvote' value={totalvote} onChange = {(e) =>setTotalvote(e.target.value)} required />
        <input type = 'submit' value = "submit"/>
    </form>
    </div>
  )
}

export default Updatec