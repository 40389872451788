import React, {useState, useEffect} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import axios from 'axios'

const Updateu = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [username, setUsername] = useState("")
    const [voteridno, setVoteridno] = useState("")
    const [gender, setGender] = useState("")
    const [dob, setDob] = useState("")
    const [fathername, setFathername] = useState("")
    const [imgurl, setImgurl] = useState("")
     
    

    useEffect(() => { 
        axios.post("http://localhost:3001/getUpdateData", {id}) 
            .then(
                (response) => {
                    setUsername(response.data.username);
                    setVoteridno(response.data.voteridno);
                    setGender(response.data.gender);
                    setDob(response.data.dob);
                    setFathername(response.data.fathername);
                    setImgurl(response.data.imgurl);
                     
                })
            .catch(
                err =>{console.log(err)},
            )
    },[id])
            const handleSubmit = (e) =>{
                e.preventDefault();
                try {
                    axios.post("http://localhost:3001/updateu", {id, username, voteridno, gender, dob, fathername, imgurl})
                    .then ((response)=>{
                        console.log(response);
                        navigate('/User');
                    })
                    .catch((err) => {console.log(err) })
                }catch(err) {
                }
            };
                

  return (
    <div>
        <form onSubmit={handleSubmit}>
        <input placeholder='Enter User name' type = 'text' name = 'username' value={username} onChange = {(e) =>setUsername(e.target.value)}  required />
        <input placeholder='Voter id no' type = 'number' name = 'voteridno' value={voteridno} onChange = {(e) =>setVoteridno(e.target.value)} required />
        <input placeholder='gender' type = 'text' name = 'gender' value={gender} onChange = {(e) =>setGender(e.target.value)} required />
        <input placeholder='DOB' type = 'number' name = 'dob' value={dob} onChange = {(e) =>setDob(e.target.value)} required />
        <input placeholder='Father name' type = 'text' name = 'fathername' value={fathername} onChange = {(e) =>setFathername(e.target.value)} required />
        <input placeholder='Imgurl' type = 'text' name = 'imgurl' value={imgurl} onChange = {(e) =>setImgurl(e.target.value)} required />
        <input type = 'submit' value = "submit"/>
    </form>
    </div>
  )
}

export default Updateu