import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Userlist from './Userlist'

const User = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);

    

    useEffect(() => {  
        axios
        .get("http://localhost:3001/Users")
        .then(
            (response) => {
                // console.log(response.data);
                setUsers(response.data);
            }
            )
        .catch(
            (error) => {
                console.log(error)
            }
        )

    }, [users]);


  return (
    <>
    
    <center><h1>Voters List</h1>
    
    </center>
    
    {/* <div className='header'>
    
        <div>id</div>
        <div>username</div>
        <div>voteridno</div>
        <div>gender</div>
        <div>dob</div>
        <div>fatherName</div>
        <div>imgurl</div>
          
    </div> */}
    
    
    {users.map(
    (users) => {
        

    return(
        <Userlist
    key={users.id}
    id={users.id}
    username={users.username}
    voteridno={users.voteridno}
    gender={users.gender}
    dob={users.dob}
    fathername={users.fathername}
    imgurl={users.imgurl}
    />
    )
    }

    )
    }
    <button onClick={()=>{navigate("/AddDatau")}}>Add Datau</button>
    <button onClick={()=>{navigate("/Report")}}>Go Back</button>
    </>
  )
}

export default User