import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Register = () => {

    const navigate = useNavigate();
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
     
    
    const handleSubmit = (e) => {
        e.preventDefault()

        try {
            axios
            .post("http://localhost:3001/Register", {username, password})
            .then(
                (response) =>{
                    console.log(response)
                navigate('/Nav')
                })
            .catch(
                err =>console.log(err)
            )
        } catch (error) {
            
        }

    }

  return (
    <>
    <div className='register'>
    <div>
    <center>
    <form onSubmit={handleSubmit}>
        <input placeholder='Enter Username' type = 'text' name = 'username' value={username} onChange = {(e) =>setUsername(e.target.value)}  required />
        <input placeholder='Enter Password' type = 'text' name = 'password' value={password} onChange = {(e) =>setPassword(e.target.value)} required />
        <input type = 'submit' value = "submit"/>
    </form>
    
  <img className='imgE' src="https://t4.ftcdn.net/jpg/07/57/74/23/240_F_757742357_wQUa0jQr3Rr5otY8GgAgJJAQ03bR9UmA.jpg" width="1200" height="500" alt="" />
    </center>

    </div>
    </div>
    {/* <button onClick={()=>navigate('/')}>Go Back</button> */}
    </>
  )
}

export default Register;