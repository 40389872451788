import React from 'react'
import Updateu from './components/Updateu'
import Contact from './components/Contact'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import User from './components/User'
import Report from './components/Report'
import Nav from './components/Nav'
import Candidate from './components/Candidate'
import AddDatac from './components/AddDatac'
import AddDatau from './components/AddDatau'
import Updatec from './components/Updatec'
import Login from './components/Login'
import Register from './components/Register'
import Navdrop from './components/Navdrop'
import Vote from './components/Vote'

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
        {/* <Route path="/Nav" element={<Nav />} /> */}
        <Route path="/" element={<Nav />} />
        <Route path="/Login" element={<Login />} />
          <Route path='/Contact' element={<Contact />}></Route>
          <Route path='/User' element={<User />}></Route>
          <Route path='/Candidate' element={<Candidate />}></Route>
          <Route path="/report" element={<Report />} />
          <Route path='/AddDatau' element={<AddDatau />}></Route>
          <Route path="/updateu/:id" element={<Updateu />} />
          <Route path='/AddDatac' element={<AddDatac />}></Route>
          <Route path="/updatec/:id" element={<Updatec />} />
          <Route path="/register" element={<Register />} />
          <Route path="/Navdrop" element={<Navdrop />} />
          <Route path="/vote" element={<Vote />} />
        </Routes>
      </BrowserRouter>

    </>
  )
}

export default App















// import React from 'react'
// import Employee from './components/Shop'
// import AddData from './components/AddData'
// import Update from './components/Update'
// import { BrowserRouter, Routes, Route } from 'react-router-dom'

// const App = () => {
//   return (
//     <>
//       <BrowserRouter>
//         <Routes>
//           <Route path="/" element={<Employee />} />
//           <Route path="/AddData" element={<AddData />} />
//           <Route path="/update/:id" element={<Update />} />


//         </Routes>
//       </BrowserRouter>

//     </>
//   )
// }

// export default App
